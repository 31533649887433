import React from 'react';
import PropTypes from 'prop-types';

// import Modal from 'react-modal';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import logo from '../images/cheeky-logo.png';
import twitter from '../images/twitter.gif';
import discord from '../images/discord.gif';
import youtube from '../images/youtube.gif';

import './mobileMenu.scss';

const MobileMenu = ({ menuOpen, setMenuOpen }) => (
  <Modal
    open={menuOpen}
    onClose={() => setMenuOpen(false)}
  >

    <div className="mobile-menu">
      <div className="mobile-menu__header">
        <a
          href="/"
          className="mobile-menu__logo"
        >
          <img src={logo} alt="Cheeky #Unts logo" />
        </a>

        <button
          type="button"
          onClick={() => setMenuOpen(false)}
          className="mobile-menu__close"
        >
          <CloseIcon fontSize="large" style={{ color: 'white' }} />
        </button>
      </div>
      <div className="mobile-menu__content">
        <div className="mobile-menu__text-links">
          <a className="mobile-menu__text-link" href="/explore">
            explore
          </a>
          <a className="mobile-menu__text-link" href="/roadmap">
            roadmap
          </a>
          <a className="mobile-menu__text-link" href="/rarity">
            rarity
          </a>
          <a className="mobile-menu__text-link" href="/team">
            team
          </a>
          <a className="mobile-menu__text-link" href="https://merch.cheekyunts.com/">
            merch
          </a>
        </div>
        <div className="mobile-menu__socials">
          <a className="mobile-menu__social-link" href="https://discord.gg/SmdSdW5ZuF">
            <img src={discord} alt="Discord" />
          </a>
          <a className="mobile-menu__social-link" href="https://twitter.com/CheekyUnts">
            <img src={twitter} alt="Twitter" />
          </a>
          <a className="mobile-menu__social-link" href="https://www.youtube.com/channel/UCKE1j4MQZA62Midj1GjxbCQ">
            <img src={youtube} alt="Youtube" />
          </a>
        </div>
        <a className="mobile__adopt-link" href="https://www.jpg.store/collection/cheekyunts">
          Adopt An Unt
        </a>
      </div>
    </div>
  </Modal>
);

MobileMenu.defaultProps = {
  menuOpen: false,
};

MobileMenu.propTypes = {
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func.isRequired,
};

export default MobileMenu;
