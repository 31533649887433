import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import MenuIcon from '@mui/icons-material/Menu';

import MobileMenu from './mobileMenu';

import logo from '../images/cheeky-logo.gif';
import twitter from '../images/twitter.gif';
import discord from '../images/discord.gif';
import youtube from '../images/youtube.gif';
import medium from '../images/medium.gif';

import './header.scss';

const Header = ({ isHome }) => {
  const [displayBackground, setDisplayBackground] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (isHome) {
      window.onscroll = () => {
        setDisplayBackground(window.pageYOffset > 100);
      };
    }
  }, [isHome]);

  return (
    <div
      className={classNames('header', {
        '-is-not-home': !isHome,
      })}
    >
      <MobileMenu
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <header
        className="header__container"
      >
        <div
          className={classNames('header__background', {
            '-is-home': isHome,
            '-display-background': displayBackground,
          })}
        />
        <div className="header__content">
          <a
            href="/"
            className={classNames('header__logo', {
              '-is-home': isHome,
              '-display-background': displayBackground,
            })}
          >
            <img src={logo} alt="Cheeky #Unts logo" />
          </a>
          <div className="header__right-mobile">
            <button
              type="button"
              onClick={() => setMenuOpen(true)}
              className="header__right-button"
            >
              <MenuIcon fontSize="large" style={{ color: 'white' }} />
            </button>
          </div>
          <div className="header__right">
            <div className="header__text-links">
              <a className="header__text-link" href="/#roadmap">
                Roadmap
              </a>
              <a className="header__text-link" href="/#team">
                Team
              </a>
              <a className="header__text-link" href="https://merch.cheekyunts.com/" target="_new">
                Merch
              </a>
              <a className="header__text-link" href="https://docs.cheekyunts.com/cheekyunts/" target="_new">
                Docs
              </a>
            </div>
            <div className="header__socials">
              <a className="header__social-link" href="https://discord.gg/SmdSdW5ZuF" target="_new">
                <img src={discord} alt="Discord" />
              </a>
              <a className="header__social-link" href="https://twitter.com/CheekyUnts" target="_new">
                <img src={twitter} alt="Twitter" />
              </a>
              <a className="header__social-link" href="https://www.youtube.com/channel/UCKE1j4MQZA62Midj1GjxbCQ" target="_new">
                <img src={youtube} alt="Youtube" />
              </a>
              <a className="header__social-link" href="https://medium.com/cheekyunts" target="_new">
                <img src={medium} alt="Medium" />
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

Header.defaultProps = {
  isHome: false,
};

Header.propTypes = {
  isHome: PropTypes.bool,
};

export default Header;
